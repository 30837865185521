import { createApp } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Buefy from '@ntohq/buefy-next';

import App from '@/App.vue';
import i18n from '@/lang/i18n';
import { createPinia } from 'pinia';
import router from '@/router';
import configData from '@/config/config';
import { configKey } from '@/symbols';
import { heremaps } from './heremaps';
import VueIntercom from '@homebaseai/vue3-intercom';
import './fontawesome';
import type { OmwConfig } from '@/types/ConfigTypes';

const app = createApp(App);
const pinia = createPinia();

app.use(VueIntercom);
app.use(heremaps);

const omwConfig: OmwConfig = window.Playwright ? window.testConfig : configData;
app.provide(configKey, omwConfig);

// eslint-disable-next-line vue/component-definition-name-casing
app.component('vue-fontawesome', FontAwesomeIcon);

app.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

app.use(pinia).use(router).use(i18n).mount('#app');

<template>
  <div
    id="details"
    class="content"
  >
    <div class="columns">
      <div class="column has-text-left">
        <p
          id="paraApptNumber"
          class="mx-2 my-0 py-0"
          v-html="
            t('app-panel-appointment-number', {
              appointmentNumber: omwStore.activityDetails?.apptNumber,
              boldStart,
              boldEnd,
            })
          "
        />
        <p
          id="paraApptWindow"
          class="mx-2 my-0 py-0"
          v-html="line1"
        />
        <p
          v-if="address"
          id="paraApptAddress"
          class="mx-2 my-0 py-0"
          v-html="address"
        />
        <p
          v-if="showDisclaimer"
          id="paraApptDisclaimer"
          class="mx-2 my-0 py-0 is-size-6 is-italic"
          style="padding-top: 0.5em"
        >
          {{ t('app-disclaimer-text') }}
        </p>

        <div class="columns is-mobile">
          <div class="column mt-2">
            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-flex-wrap-wrap"
              style="gap: 4px"
            >
              <b-button
                v-if="rescheduleEnabled"
                class="has-text-weight-semibold"
                style="width: 10rem"
                size="is-small"
                type="is-rebook-button"
                @click="showReschedule"
              >
                {{ t('rebook-rebook-button-label') }}
              </b-button>
              <b-button
                v-if="cancelEnabled"
                class="has-text-weight-semibold"
                style="width: 10rem"
                size="is-small"
                type="is-danger has-text-weight-semibold"
                @click="handleCancel"
              >
                {{ t('rebook-cancel-button-label') }}
              </b-button>
              <b-button
                v-if="calendarImportEnabled"
                class="has-text-weight-semibold"
                style="width: 10rem"
                size="is-small"
                type="is-calendar-button"
                @click="createCalendarFile"
              >
                {{ t('calendar-import-button-label') }}
              </b-button>
              <b-button
                v-if="calendarImportEnabled"
                class="has-text-weight-semibold"
                style="width: 10rem"
                size="is-small"
                type="is-calendar-button"
                @click="createGoogleCalendarLink"
              >
                {{ t('calendar-google-button-label') }}
              </b-button>
              <b-button
                v-if="omwConfig.notes.enabled"
                size="is-small"
                style="width: 10rem"
                class="has-text-weight-semibold"
                type="is-note-button"
                @click="showNotesModal = true"
              >
                {{ t('notes-main-button-label') }}
              </b-button>
              <b-button
                v-if="omwConfig.photos.enabled"
                class="has-text-weight-semibold"
                style="width: 10rem"
                size="is-small"
                type="is-photo-button"
                @click="showAttachPhoto"
              >
                {{ t('manage-photo-button-label') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-if="omwConfig.notes.enabled"
      v-model="showNotesModal"
    >
      <notes-modal @close="showNotesModal = false" />
    </b-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, inject } from 'vue';
import { useOmwStore } from '@/store/omw';
import { DateTime } from 'luxon';
import useUtilities from '@/composables/useUtilities';
import useAppointmentUtils from '@/composables/useAppointmentUtils';
import useCalendarCreator from '@/composables/useCalendarCreator';

/*
 * TODO this is a bit of a hack, but works for now
 * We should be using:
 * import { DialogProgrammatic } from '@ntohq/buefy-next';
 * but that gives an error: Uncaught TypeError: (intermediate value).confirm is not a function
 * workaround given here https://github.com/ntohq/buefy-next/discussions/152
 * */
import { getCurrentInstance } from 'vue';
const self = getCurrentInstance();

import NotesModal from '@/components/NotesModal.vue';

import useReschedule from '@/services/reschedule';
import { configKey } from '@/symbols';
import { useI18n } from 'vue-i18n';

const boldStart = `<span style="font-weight: bolder" class="has-text-appointment-details-panel-text">`;
const boldEnd = `</span>`;

const { routeToPage } = useUtilities();
const {
  formattedServiceWindowStart,
  formattedServiceWindowEnd,
  formattedDeliveryWindowStart,
  formattedDeliveryWindowEnd,
  calculatedEta,
} = useAppointmentUtils();
const { createIcsFile, buildDescriptionText, buildGoogleCalendarLink } = useCalendarCreator();
const { cancelAppointment } = useReschedule();

const omwConfig = inject(configKey)!;
const omwStore = useOmwStore();
const { t } = useI18n();

const line1 = ref<string>();
const address = ref<string>();
const googleCalendarLink = ref<string>();

const startedStatusText = computed(() => {
  return t('app-panel-started', {
    name: apptPanelEngineerName.value,
    boldStart,
    boldEnd,
  });
});

const completeStatusText = computed(() => {
  return t('app-panel-completed', {
    name: apptPanelEngineerName.value,
    boldStart,
    boldEnd,
  });
});

const cancelledStatusText = computed(() => {
  return t('app-panel-cancelled', {
    contactCentreNumber: omwConfig.display.contactCentreNo,
  });
});

const abortedStatusText = computed(() => {
  return t('app-panel-aborted', {
    contactCentreNumber: omwConfig.display.contactCentreNo,
  });
});

const notDoneStatusText = computed(() => {
  return t('app-panel-notdone', {
    contactCentreNumber: omwConfig.display.contactCentreNo,
  });
});

const builtAddress = computed(() => {
  if (omwStore.engineerDetails && omwStore.activityDetails) {
    const substitutions = {
      name: apptPanelEngineerName.value,
      street: omwStore.activityDetails.streetAddress,
      city: omwStore.activityDetails.city,
      postcode: omwStore.activityDetails.postalCode,
      boldStart,
      boldEnd,
    };
    if (omwStore.apptInPast) {
      return t('app-panel-street-address', substitutions);
    }
    if (!omwStore.apptInFuture) {
      return t('app-panel-street-address-present-prefix', substitutions);
    } else {
      return t('app-panel-street-address-future-prefix', substitutions);
    }
  }
  return '';
});

const pendingStatusText = computed(() => {
  // Check to see if this activity is in the future
  if (omwStore.apptInFuture) {
    return pendingFutureText.value;
  }
  if (omwStore.apptInPast) {
    return '';
  } else {
    return pendingTodayText.value;
  }
});

const formattedDate = computed(() => {
  const dateObj = DateTime.fromISO(omwStore.activityDetails!.date);
  const dateFormat = omwConfig.display.dateFormat;
  return dateFormat
    ? dateObj.toFormat(dateFormat)
    : dateObj.toLocaleString({
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
});

const pendingFutureText = computed(() => {
  let start;
  let end;
  if (omwConfig.display.showDeliveryForFuture) {
    // Check if we have a delivery window, if not, use service window
    start = formattedDeliveryWindowStart ?? formattedServiceWindowStart;
    end = formattedDeliveryWindowEnd ?? formattedServiceWindowEnd;
  } else {
    start = formattedServiceWindowStart;
    end = formattedServiceWindowEnd;
  }

  return t('app-panel-future-pending', {
    start,
    end,
    date: formattedDate.value,
    boldStart,
    boldEnd,
  });
});

const pendingTodayText = computed(() => {
  let arrivalTimeEnd, arrivalTimeStart;
  // If this job is not the next in the engineer's route, or we don't have ETA calculation turned on, use the provided value
  if (omwStore.activityDetails?.positionInRoute !== 1 || !omwConfig.display.eta.useCalculatedEta) {
    arrivalTimeStart = formattedDeliveryWindowStart ?? formattedServiceWindowStart;
    arrivalTimeEnd = formattedDeliveryWindowEnd ?? formattedServiceWindowEnd;
  } else {
    // If this job is the next in the engineer's route, use an ETA calculated from the map provider
    arrivalTimeStart = calculatedEta?.bufferedStartDate ?? formattedDeliveryWindowStart; // fall back to provided value if necessary
    arrivalTimeEnd = calculatedEta?.bufferedEndDate ?? formattedDeliveryWindowEnd; // fall back to provided value if necessary
  }
  const substitutions = {
    name: apptPanelEngineerName.value,
    start: arrivalTimeStart,
    end: arrivalTimeEnd,
    boldStart,
    boldEnd,
  };
  // One stop out
  if (omwStore.activityDetails?.positionInRoute === -1) {
    return t('app-panel-today-pending', substitutions);
  }
  // Next stop
  else {
    return t('app-panel-today-enroute', substitutions);
  }
});

const enrouteStatusText = computed(() => {
  let start;
  let end;
  if (omwConfig.display.eta.useCalculatedEta && calculatedEta?.bufferedStartDate) {
    start = calculatedEta.bufferedStartDate;
    end = calculatedEta.bufferedEndDate;
  } else {
    start = formattedDeliveryWindowStart; // fall back to provided value if necessary
    end = formattedDeliveryWindowEnd; // fall back to provided value if necessary
  }

  return t('app-panel-today-enroute', {
    name: apptPanelEngineerName.value,
    start,
    end,
    boldStart,
    boldEnd,
  });
});

const apptPanelEngineerName = computed(() => {
  if (omwStore.apptInFuture) {
    return t('future-engineer-name');
  }
  return omwStore.engineerDetails?.nameOverride || t('appointment-panel-default-engineer-name');
});

const isPastRebookCutoffTime = computed(() => {
  const daysIntoFuture = omwConfig.reschedule.rebookCutoff?.numberOfDaysInFuture ?? 0;
  const cutoffTime = omwConfig.reschedule.rebookCutoff?.cutoffTime ?? '23:59';
  return isPastCutoffTime(daysIntoFuture, cutoffTime);
});

const isPastCancelCutoffTime = computed(() => {
  const daysIntoFuture = omwConfig.reschedule.cancelCutoff?.numberOfDaysInFuture ?? 0;
  const cutoffTime = omwConfig.reschedule.cancelCutoff?.cutoffTime ?? '23:59';
  return isPastCutoffTime(daysIntoFuture, cutoffTime);
});

const allowUserRebook = computed(() => {
  // We want to default to true if not specified
  const contactCentreRebook =
    typeof omwConfig.reschedule.allowContactCentreRebook !== 'undefined'
      ? omwConfig.reschedule.allowContactCentreRebook
      : true;
  if (contactCentreRebook) return true; // If contact centre user can rebook, this computed variable should always return true

  // Contact centre users aren't allowed to rebook so check if this is coming from a contact centre request
  return omwStore.clickOrigin !== 'portal';
});

const tooLateToRebookCancelStatuses = computed(() => {
  return omwConfig.reschedule.tooLateToRebookCancelStatuses || ['cancelled', 'canceled', 'completed', 'enroute'];
});

const cancelEnabled = computed(() => {
  return (
    omwConfig.reschedule.cancelEnabled && // Reschedule option is turned on
    !tooLateToRebookCancelStatuses.value.includes(omwStore.activityDetails?.status!) && // Activity is in a status that can be rescheduled
    omwStore.activityDetails?.rebookable && // Activity type is rebookable
    !omwStore.apptInPast && // Activity is not in the past
    allowUserRebook.value && // User is allowed to rebook e.g. not a contact centre agent if that type is excluded
    !isPastCancelCutoffTime.value // If we're not allowed to rebook on the day, and appointment is today
  );
});

const rescheduleEnabled = computed(() => {
  return (
    omwConfig.reschedule.rebookEnabled && // Reschedule option is turned on
    !tooLateToRebookCancelStatuses.value.includes(omwStore.activityDetails?.status!) && // Activity is in a status that can be rescheduled
    omwStore.activityDetails?.rebookable && // Activity type is rebookable
    !omwStore.apptInPast && // Activity is not in the past
    allowUserRebook.value && // User is allowed to rebook e.g. not a contact centre agent if that type is excluded
    !isPastRebookCutoffTime.value // If we're not allowed to rebook on the day, and appointment is today
  );
});

const calendarImportEnabled = computed(() => {
  return omwConfig.calendarImport.enabled && omwStore.activityDetails?.status === 'pending';
});

const showDisclaimer = computed(() => {
  return omwStore.activityDetails?.status === 'enroute' || omwStore.activityDetails?.status === 'pending';
});

watch(
  () => omwStore.activityDetails,
  () => {
    updateApptDetails();
  },
  { deep: true, immediate: true },
);

watch(
  () => calculatedEta,
  () => {
    updateApptDetails();
  },
  { deep: true, immediate: true },
);

function updateApptDetails() {
  if (!omwStore.activityDetails || !omwStore.activityDetails.status) return;

  const status = omwStore.activityDetails.status.toLowerCase();

  switch (status) {
    case 'enroute': {
      line1.value = enrouteStatusText.value;
      address.value = builtAddress.value;
      break;
    }
    case 'pending': {
      line1.value = pendingStatusText.value;
      address.value = builtAddress.value;
      break;
    }
    case 'started': {
      line1.value = startedStatusText.value;
      address.value = undefined;
      break;
    }
    case 'completed': {
      line1.value = completeStatusText.value;
      address.value = undefined;
      break;
    }
    case 'canceled':
    case 'cancelled': {
      line1.value = cancelledStatusText.value;
      address.value = undefined;
      break;
    }
    case 'not done':
    case 'notdone': {
      line1.value = notDoneStatusText.value;
      address.value = undefined;
      break;
    }
    case 'aborted': {
      line1.value = abortedStatusText.value;
      address.value = undefined;
      break;
    }
  }
}

function isPastCutoffTime(daysIntoFuture: number, cutoffTime: string) {
  if (!omwStore.activityDetails?.date) return true;
  const apptDate = DateTime.fromISO(omwStore.activityDetails.date);
  const now = DateTime.now();
  const cutoffArray = cutoffTime.split(':');
  const cutoffHour = parseInt(cutoffArray[0]);
  const cutoffMins = parseInt(cutoffArray[1]);
  const cutoffDateTime = apptDate
    .minus({
      days: daysIntoFuture,
    })
    .startOf('day')
    .set({
      hour: cutoffHour,
      minute: cutoffMins,
    });
  return now > cutoffDateTime;
}

const showNotesModal = ref(false);

async function submitCancel() {
  try {
    omwStore.isLoading = true;
    await cancelAppointment({ token: omwStore.token! });
    self?.proxy?.$buefy.toast.open(t('rebook-appointment-cancelled'));
    await routeToPage('Cancelled', 'replace');
  } catch (err) {
    self?.proxy?.$buefy.dialog.alert({
      title: 'Error',
      message: t('rebook-cancel-error', {
        contactNumber: omwConfig.display.contactCentreNo,
      }),
      type: 'is-danger',
      hasIcon: true,
      icon: 'times-circle',
      ariaRole: 'alertdialog',
      ariaModal: true,
    });
  } finally {
    omwStore.isLoading = false;
  }
}

function handleCancel() {
  if (!notifyOfBlockedRebook()) {
    self?.proxy?.$buefy.dialog.confirm({
      title: t('rebook-cancel-title'),
      message: t('rebook-cancel-message'),
      confirmText: t('rebook-cancel-confirm'),
      cancelText: t('rebook-return-label'),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: submitCancel,
    });
  }
}

function showReschedule() {
  if (!notifyOfBlockedRebook()) routeToPage('Rebook');
}

function notifyOfBlockedRebook() {
  if (
    omwStore.activityDetails?.rebookBlockedUntil &&
    DateTime.utc() < DateTime.fromISO(omwStore.activityDetails.rebookBlockedUntil)
  ) {
    self?.proxy?.$buefy.dialog.alert({
      title: t('rebook-blocked-title'),
      message: t('rebook-blocked-message'),
      confirmText: t('rebook-close-btn-label'),
      type: 'is-info',
      hasIcon: true,
    });
    return true;
  }
  return false;
}

function createCalendarFile() {
  omwStore.isLoading = true;

  try {
    if (!omwStore.activityDetails || !omwStore.token) return; // TODO handle error
    const description = buildDescriptionText(false, omwStore.activityDetails);
    createIcsFile({
      address: `${omwStore.activityDetails.streetAddress}, ${omwStore.activityDetails.city}, ${omwStore.activityDetails.postalCode}`,
      description,
      summary: t('calendar-entry-title', {
        apptNumber: omwStore.activityDetails.apptNumber,
      }),
      timezone: omwStore.activityDetails.timezone,
      start: omwStore.activityDetails.startTime!,
      date: omwStore.activityDetails.date,
      apptStartTime: omwStore.activityDetails.trimServiceWindowStart!,
      apptEndTime: omwStore.activityDetails.trimServiceWindowEnd!,
      token: omwStore.token,
      alarms: omwConfig.calendarImport.alarms || [],
      organizer: omwConfig.calendarImport.organizer,
    });
  } catch (err) {
    console.log(err); // TODO handle error
  } finally {
    omwStore.isLoading = false;
  }
}

function createGoogleCalendarLink() {
  omwStore.isLoading = true;
  try {
    if (!omwStore.activityDetails || !omwStore.token) return; // TODO handle error
    const description = buildDescriptionText(true, omwStore.activityDetails);
    googleCalendarLink.value = buildGoogleCalendarLink({
      address: `${omwStore.activityDetails.streetAddress}, ${omwStore.activityDetails.city}, ${omwStore.activityDetails.postalCode}`,
      description,
      summary: t('calendar-entry-title', {
        apptNumber: omwStore.activityDetails.apptNumber,
      }),
      timezone: omwStore.activityDetails.timezone,
      start: omwStore.activityDetails.startTime!,
      date: omwStore.activityDetails.date,
      apptStartTime: omwStore.activityDetails.trimServiceWindowStart!,
      apptEndTime: omwStore.activityDetails.trimServiceWindowEnd!,
      token: omwStore.token,
      alarms: omwConfig.calendarImport.alarms || [],
      organizer: omwConfig.calendarImport.organizer,
    });
    window.open(googleCalendarLink.value, '_blank');
  } catch (err) {
    console.log(err); // TODO handle error
  } finally {
    omwStore.isLoading = false;
  }
}

function showAttachPhoto() {
  routeToPage('PhotoManage');
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.content {
  @if $appointment-details-panel {
    background-color: $appointment-details-panel;
  } @else {
    background-color: $engineer-panel;
  }
  @if $appointment-details-panel-text {
    color: $appointment-details-panel-text;
  } @else {
    color: $engineer-panel-text;
  }
  border-radius: 4px;
}
</style>

import { createRouter, createWebHistory } from 'vue-router';
import OnMyWay from '@/views/HomePage.vue';
import CustomLoading from '@/components/CustomLoading.vue';
import LandingPage from '@/components/LandingPage.vue';

const routes = [
  {
    // Route which supports token being provided in the path. Read path 'l' as for Landing or Leadent. Re-directs to '/'.
    path: '/l/:token?',
    name: 'Landing',
    component: LandingPage,
  },
  {
    path: '/home',
    name: 'Home',
    component: OnMyWay,
  },
  {
    path: '/',
    name: 'Loading',
    component: CustomLoading,
  },
  {
    path: '/cancelled',
    name: 'Cancelled',
    component: () => import('@/components/appointmentBooking/AppointmentCancelConfirmation.vue'),
  },
  {
    path: '/rebook',
    name: 'Rebook',
    component: () => import('@/components/appointmentBooking/AppointmentRebookContainer.vue'),
  },
  {
    path: '/confirmed',
    name: 'Confirmed',
    component: () => import('@/components/appointmentBooking/AppointmentRebookConfirmation.vue'),
  },
  {
    path: '/photo/manage',
    name: 'PhotoManage',
    component: () => import('@/components/photos/PhotoManage.vue'),
  },
  {
    path: '/photo/manage/attach',
    name: 'PhotoAttach',
    component: () => import('@/components/photos/PhotoAttach.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;

<template>
  <div
    v-if="liveCampaign && clickUrl"
    id="divMarketing"
    class="has-text-centered"
    @click="recordClick"
  >
    <a
      :href="clickUrl"
      target="_new"
    >
      <img
        :src="liveCampaign.url"
        style="border-radius: 5px"
        alt=""
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useOmwStore } from '@/store/omw';
import useMarketing from '@/services/marketing';
import useUtilities from '@/composables/useUtilities';
import type { MarketingCampaignPreview, MarketingCampaignResponse } from '@/types/MarketingTypes';

const omwStore = useOmwStore();
const { submitClick, getCampaign } = useMarketing();
const { engineerName } = useUtilities();

const liveCampaign = ref<MarketingCampaignResponse | MarketingCampaignPreview>();

const emit = defineEmits(['campaign-loaded', 'campaign-failed']);

const clickUrl = computed(() => {
  if (!liveCampaign.value?.clickUrl) return;
  if (liveCampaign.value.clickUrl.startsWith('https://') || liveCampaign.value.clickUrl.startsWith('http://')) {
    return liveCampaign.value.clickUrl;
  }
  return `https://${liveCampaign.value.clickUrl}`;
});

onMounted(async () => {
  try {
    liveCampaign.value = await getCampaign(omwStore.datasetId!, omwStore.activityDetails!, omwStore.clickOrigin!);
    if (liveCampaign.value) {
      emit('campaign-loaded', true);
    }
  } catch (err) {
    emit('campaign-failed', true);
  }
});

async function recordClick() {
  if (
    !liveCampaign.value ||
    !liveCampaign.value.campaignId ||
    !omwStore.activityDetails ||
    !omwStore.datasetId ||
    !omwStore.clickOrigin
  )
    return;
  try {
    await submitClick(
      omwStore.activityDetails,
      engineerName(),
      liveCampaign.value as MarketingCampaignResponse,
      omwStore.datasetId,
      omwStore.clickOrigin,
    );
  } catch (err) {
    // Do nothing... user doesn't need to know
    console.error(err);
  }
}
</script>

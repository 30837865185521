<template>
  <span>
    <span
      v-if="trustpilotLoaded"
      ref="trustboxRef"
      class="trustpilot-widget"
      :data-locale="omwConfig.trustpilot.dataLocale || 'en-GB'"
      :data-template-id="omwConfig.trustpilot.dataTemplateId || ''"
      :data-businessunit-id="omwConfig.trustpilot.dataBusinessUnitId || ''"
      :data-style-height="omwConfig.trustpilot.dataStyleHeight || '0px'"
      :data-style-width="omwConfig.trustpilot.dataStyleWidth || '0px'"
      :data-theme="omwConfig.trustpilot.dataTheme || 'light'"
    >
      <a
        :href="omwConfig.trustpilot.url || ''"
        target="_blank"
      >
        Trustpilot
      </a>
    </span>
    <a
      v-else
      :href="omwConfig.trustpilot.url"
      target="_blank"
      rel="noopener noreferrer"
    >
      Trustpilot
    </a>
  </span>
</template>

<script setup lang="ts">
import { inject, nextTick, onMounted, ref } from 'vue';
import { configKey } from '@/symbols';

const omwConfig = inject(configKey)!;

const trustpilotLoaded = ref(false);
const trustboxRef = ref<HTMLElement>();

onMounted(() => {
  if (window.Trustpilot) {
    trustpilotLoaded.value = true;
    nextTick(() => {
      window.Trustpilot.loadFromElement(trustboxRef.value);
    });
  }
});
</script>

<style scoped>
img.displayed {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 307px;
}
</style>

<template>
  <div v-if="omwStore.engineerDetails?.licencePlate">
    <div style="margin-bottom: 0.5em">{{ $t('engineer-panel-licence-plate') }}</div>
    <span :class="licenceDesign">
      {{ omwStore.engineerDetails?.licencePlate }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useOmwStore } from '@/store/omw';

const omwStore = useOmwStore();

const licenceDesign = computed(() => {
  if (['uk', 'gb'].some((element) => omwStore.token!.includes(element))) {
    return 'isUk';
  }
  if (['monitor', 'de', 'europe', 'france'].some((element) => omwStore.token!.includes(element))) {
    return 'isEurope';
  }
  if (['apac', 'turkey'].some((element) => omwStore.token!.includes(element))) {
    return 'isAsia';
  }
  if (['usa'].some((element) => omwStore.token!.includes(element))) {
    return 'isUSA';
  }
  return 'isUk';
});
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'Charles Wright';
  src: url('/public/fonts/charles_wright/CharlesWright-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'FE Schrift';
  src: url('/public/fonts/fe_schrift/GlNummernschildEng-XgWd.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Plat Nomor';
  src: url('/public/fonts/plat_nomor/PlatNomor-WyVnn.ttf');
  font-weight: bold;
}

// Maybe these should be put in config instead?
.isUk {
  border-radius: 5px;
  border: 3px solid #383838;
  color: #333;
  font: 1.8em 'Charles Wright';
  background-color: #e8c333;
  padding: 0 20px;
}

.isEurope {
  border-radius: 5px;
  border: 3px solid #383838;
  color: #333;
  font: 2.4em 'FE Schrift';
  padding: 2px 12px 2px 24px;
  background: linear-gradient(90deg, #0000b2 0%, #0000b2 15%, #e8c333 15%, #e8c333 100%);
  &::before {
    content: 'DE';
    position: relative;
    left: -20px;
    font-size: 0.4em;
    color: #fff;
  }
}

.isAsia {
  border-radius: 5px;
  border: 3px solid #a3a3a3;
  color: #fff;
  font: 1.8em 'Charles Wright';
  padding: 0 20px;
  background-color: #333;
}

.isUSA {
  border-radius: 5px;
  border: 3px solid #a3a3a3;
  font: 1.8em 'Plat Nomor';
  color: #333;
  padding: 4px 20px 0;
  background-color: #fff;
}
</style>

<template>
  <div>
    <div
      id="map"
      ref="map"
      class="here-map"
    />

    <div
      class="buttons mt-2 mb-3"
      style="justify-content: center"
    >
      <b-button
        type="is-omw-buttons"
        class="has-text-weight-semibold custom-button mr-4"
        @click="recenter"
      >
        {{ $t('map-centre-map-button-label') }}
      </b-button>
      <b-button
        type="is-omw-buttons"
        class="has-text-weight-semibold custom-button ml-4"
        @click="getData()"
      >
        {{ $t('map-update-data-button-label') }}
      </b-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { useOmwStore } from '@/store/omw';
import { supportsPassiveEvents } from 'detect-passive-events';
import style from '@/sass/variables.scss?inline';
import { configKey } from '@/symbols';
import useUtilities from '@/composables/useUtilities';
import type { OmwResponseActivity, OmwResponseResource } from '@/types/AppointmentTypes';
import type { DtgIsoFull } from '@/types/TimeTypes';

const { getData } = useUtilities();
const heremaps = inject('heremaps')!;
const omwConfig = inject(configKey)!;
const omwStore = useOmwStore();

const routeColor = ref(style.route || '#245A9D');
const pixelRatio = ref(1.0);
// const showLegend = false;
const map = ref<H.Map>();
const platform = ref<H.service.Platform>();
const defaultLayers = ref<H.service.DefaultLayers>();
const behavior = ref<H.mapevents.Behavior>();
const markerGroup = ref<H.map.Group>();
const router = ref<H.service.RoutingService>();
const ui = ref<H.ui.UI>();
const center = ref<H.geo.Rect>();
const routeLine = ref<H.map.Polyline>();
const engineerMarker = ref<H.map.Marker>();
const activityMarker = ref<H.map.Marker>();

const zoomLevel = computed(() => {
  return !bothMarkersPresent.value ? 16 : undefined;
});

const bothMarkersPresent = computed(() => {
  return !!(activityMarker.value && engineerMarker.value);
});

const showEngineerMarker = computed(() => {
  if (omwStore.apptInFuture) return false;
  return omwStore.engineerDetails && omwStore.engineerDetails.latitude && omwStore.engineerDetails.longitude;
});

const showHomeMarker = computed(() => {
  return omwStore.activityDetails && omwStore.activityDetails.latitude && omwStore.activityDetails.longitude;
});

watch(center, (newVal) => {
  try {
    map.value?.getViewModel().setLookAtData({ bounds: newVal, zoom: zoomLevel.value });
  } catch (err) {
    console.log(err); // TODO - handle error
  }
});

watch(
  () => omwStore.engineerDetails!,
  (newVal: OmwResponseResource) => {
    if (showEngineerMarker.value) {
      setEngineerMarker(newVal);
    }
  },
  { deep: true, immediate: false },
);

watch(
  () => omwStore.activityDetails!,
  (newVal: OmwResponseActivity) => {
    if (showHomeMarker.value) {
      setActivityMarker(newVal);
    }
  },
  { deep: true, immediate: false },
);

onMounted(() => {
  nextTick(async () => {
    const api = await heremaps.load();
    const defaultCentreLat = omwConfig.here.centreLat;
    const defaultCentreLng = omwConfig.here.centreLng;
    const defaultZoom = omwConfig.here.defaultZoom;
    platform.value = new api.service.Platform({
      apikey: omwConfig.here.apiKey,
    });
    if (!markerGroup.value) {
      markerGroup.value = new api.map.Group();
    }
    pixelRatio.value = api.devicePixelRatio || 1;
    defaultLayers.value = platform.value?.createDefaultLayers({
      tileSize: pixelRatio.value === 1 ? 256 : 512,
      ppi: pixelRatio.value === 1 ? undefined : 320,
      crossOrigin: true,
    });
    map.value = new api.Map(map.value, defaultLayers.value?.vector.normal.map, {
      zoom: defaultZoom,
      center: {
        lat: defaultCentreLat,
        lng: defaultCentreLng,
      },
      pixelRatio: pixelRatio.value,
    });
    window.addEventListener('resize', handleResize, supportsPassiveEvents ? { passive: true } : false);
    behavior.value = new api.mapevents.Behavior(new api.mapevents.MapEvents(map.value));
    // Create the default UI components
    ui.value = api.ui.UI.createDefault(map.value, defaultLayers.value);
    ui.value?.setUnitSystem(omwConfig.here.imperial ? api.ui.UnitSystem.IMPERIAL : api.ui.UnitSystem.METRIC);
    handleZoomOption();
    if (!markerGroup.value) markerGroup.value = new api.map.Group();
    if (markerGroup.value) map.value?.addObject(markerGroup.value);
    router.value = platform.value?.getRoutingService(undefined, 8);
    if (showHomeMarker.value && omwStore.activityDetails) {
      await setActivityMarker(omwStore.activityDetails);
    }
    if (showEngineerMarker.value && omwStore.engineerDetails) {
      await setEngineerMarker(omwStore.engineerDetails);
    }
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

function handleZoomOption() {
  if (!omwConfig.here.showZoom) {
    const zoom = ui.value?.getControl('zoom');
    zoom?.setDisabled(true);
    zoom?.setVisibility(false);
    behavior.value?.disable(window.H.mapevents.Behavior.WHEELZOOM);
    behavior.value?.disable(window.H.mapevents.Behavior.DBLTAPZOOM);
    behavior.value?.enable(window.H.mapevents.Behavior.DRAGGING);
  }
}

function handleResize() {
  map.value?.getViewPort().resize();
}

function recenter() {
  map.value?.getViewModel().setLookAtData({ bounds: center.value });
}
function removeExistingEngineerMarker() {
  if (markerGroup.value && engineerMarker.value) {
    markerGroup.value.removeObject(engineerMarker.value);
    engineerMarker.value = undefined;
  }
  if (map.value && routeLine.value) {
    map.value.removeObject(routeLine.value);
  }
}

function removeExistingActivityMarker() {
  if (markerGroup.value && activityMarker.value) {
    markerGroup.value.removeObject(activityMarker.value);
    activityMarker.value = undefined;
  }
}

async function setActivityMarker(activityDetails: OmwResponseActivity) {
  const api = await heremaps.load();
  removeExistingActivityMarker();
  if (activityDetails.longitude && activityDetails.latitude) {
    const icon = new api.map.Icon(omwConfig.display.activityIcon.svg, {
      size: {
        w: omwConfig.display.activityIcon.width,
        h: omwConfig.display.activityIcon.height,
      },
      anchor: {
        x: omwConfig.display.activityIcon.anchorX,
        y: omwConfig.display.activityIcon.anchorY,
      },
    });
    activityMarker.value = new api.map.Marker(
      {
        lat: activityDetails.latitude,
        lng: activityDetails.longitude,
      },
      { icon },
    );
    if (!markerGroup.value) markerGroup.value = new api.map.Group();
    if (activityMarker.value) markerGroup.value?.addObject(activityMarker.value);
    center.value = markerGroup.value?.getBoundingBox();
    try {
      map.value?.getViewModel().setLookAtData({
        bounds: center.value,
        zoom: zoomLevel.value,
      });
    } catch (err) {
      console.log(err);
    }
  }
}

function adjustBoundingBox() {
  // Adjusts the bound box in include the right amount of padding to ensure icons are shown in full
  if (!routeLine.value) return;
  const bounds = routeLine.value.getBoundingBox();
  // Determine the icon dimension (height or width) in px. We'll add this to all sides for simplicity
  const maxIconDimension = Math.max(
    omwConfig.display.engineerIcon.width,
    omwConfig.display.engineerIcon.height,
    omwConfig.display.activityIcon.width,
    omwConfig.display.activityIcon.height,
  );
  const canvas = document.querySelector('#map canvas') as HTMLCanvasElement;

  // Divide the width/height in degrees by width/height in pixels to get degrees per pixel and multiply by
  // desired amount. (Multiplication done first so as not to lose precision as we're dealing with small numbers)
  const xDelta = (maxIconDimension * bounds.getWidth()) / canvas.width;
  const yDelta = (maxIconDimension * bounds.getHeight()) / canvas.height;

  center.value = new H.geo.Rect(
    bounds.getTop() + yDelta,
    bounds.getLeft() - xDelta,
    bounds.getBottom() - yDelta,
    bounds.getRight() + xDelta,
  );
}

function displayRoute() {
  const routingParameters = {
    routingMode: 'fast',
    transportMode: 'car',
    // The start point of the route:
    origin: `${omwStore.engineerDetails?.latitude},${omwStore.engineerDetails?.longitude}`,
    // The end point of the route:
    destination: `${omwStore.activityDetails?.latitude},${omwStore.activityDetails?.longitude}`,
    return: 'polyline',
  };
  try {
    router.value?.calculateRoute(
      routingParameters,
      (result) => {
        let lineString;
        if (result.routes.length) {
          omwStore.arrivalTime = result?.routes?.[0]?.sections?.[0]?.arrival?.time as DtgIsoFull;
          lineString = H.geo.LineString.fromFlexiblePolyline(result.routes[0].sections[0].polyline);
          routeLine.value = new H.map.Polyline(lineString, {
            style: {
              strokeColor: routeColor.value,
              lineWidth: 2,
            },
          });
          map.value?.addObject(routeLine.value);
          adjustBoundingBox();
        }
      },
      (error: any) => {
        console.warn(error); // TODO - handle error
      },
    );
  } catch (err) {
    console.log(err); // TODO - handle error
  }
}

async function setEngineerMarker(engineerDetails: OmwResponseResource) {
  const api = await heremaps.load();
  removeExistingEngineerMarker();
  if (engineerDetails.latitude && engineerDetails.longitude) {
    const icon = new api.map.Icon(omwConfig.display.engineerIcon.svg, {
      size: {
        w: omwConfig.display.engineerIcon.width,
        h: omwConfig.display.engineerIcon.height,
      },
      asCanvas: true,
      anchor: {
        x: omwConfig.display.engineerIcon.anchorX,
        y: omwConfig.display.engineerIcon.anchorY,
      },
    });
    engineerMarker.value = new api.map.Marker(
      {
        lat: engineerDetails.latitude,
        lng: engineerDetails.longitude,
      },
      { icon },
    );
    if (!markerGroup.value) markerGroup.value = new api.map.Group();
    if (engineerMarker.value) markerGroup.value?.addObject(engineerMarker.value);

    if (!center.value) {
      center.value = markerGroup.value?.getBoundingBox();
    }
    await nextTick(() => {
      if (engineerMarker.value && activityMarker.value) {
        displayRoute();
      }
    });
  }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.here-map {
  margin-top: 0;
  display: inline-block;
  cursor: grab;
  width: 100%;
  height: v-bind('omwConfig.here.mapHeightMobile');
}

@media (min-width: 769px) {
  .here-map {
    height: v-bind('omwConfig.here.mapHeightDesktop');
  }
}

.custom-button {
  width: 8em;
}
</style>

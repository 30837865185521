import type { OmwResponse } from '@/types/AppointmentTypes';
import { inject } from 'vue';
import { configKey } from '@/symbols';

export default function useOmwService() {
  const omwConfig = inject(configKey)!;

  async function getOmwData(token: string, clickOrigin?: string, passcode?: string): Promise<OmwResponse> {
    const params = new URLSearchParams({ token });
    if (passcode) params.append('passcode', passcode);
    if (clickOrigin) params.append('clickOrigin', clickOrigin);

    const response = await fetch(`${omwConfig.omwServer.url}?` + params, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': omwConfig.omwServer.apiKey,
      },
      mode: 'cors',
    });
    if (response.status === 200) {
      return await response.json();
    }
    throw new Error(`${response.status}`);
  }

  return { getOmwData };
}

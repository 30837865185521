<template>
  <div
    class="feedback-form-style"
    :style="feedbackDivStyle"
  >
    <div v-show="localScreen !== 'finish' && localScreen !== 'feedbackContact'">
      <p class="has-text-custom-one has-text-centered has-text-weight-semibold is-size-6 is-italic">
        {{ $t('feedback-service-intro') }}
      </p>
      <p class="has-text-centered has-text-black has-text-weight-semibold is-size-5">
        {{ $t('feedback-service-question') }}
      </p>
      <b-rate
        v-model="feedbackRating"
        icon-pack="fas"
        size="is-large"
        style="justify-content: center"
        @change="processFeedback()"
      />

      <b-field
        v-if="ratingSelected"
        :label="$t('feedback-nps-question')"
      >
        <b-slider
          v-model="feedbackNps"
          :type="sliderClass"
          :min="-1"
          :max="10"
          ticks
          :tooltip="false"
          rounded
        />
      </b-field>

      <p
        v-if="feedbackNps >= 0"
        class="has-text-weight-semibold has-text-black has-text-centered mb-2"
      >
        {{
          t('feedback-selected-nps-text', {
            value: feedbackNps,
          })
        }}
      </p>

      <textarea
        v-if="ratingSelected"
        v-model="feedbackText"
        class="textarea-style"
        :placeholder="$t(`feedback-any-comments-question`)"
        type="textarea"
        rows="5"
        maxlength="300"
      />
      <div>
        <b-button
          v-if="showNext"
          style="float: right; z-index: 999"
          type="is-feedback-buttons"
          @click.prevent="showFeedbackContactScreen"
        >
          {{ $t('feedback-next-button-label') }}
        </b-button>
      </div>
    </div>

    <div
      v-show="localScreen === 'feedbackContact'"
      style="text-align: center"
    >
      <p
        class="has-text-centered has-text-black has-text-weight-semibold"
        style="overflow-wrap: break-word; padding: 1em 3em 1em 3em"
      >
        {{ $t(`feedback-leave-contact-details-question`) }}
      </p>
      <b-field>
        <b-input
          v-model="feedbackName"
          name="contactName"
          :placeholder="$t('feedback-name-label')"
          type="text"
          icon="file-signature"
        />
      </b-field>

      <Field
        v-slot="{ errorMessage, field, meta }"
        rules="email"
        name="Email"
        validate-on-model-update
      >
        <b-field
          :variant="errorMessage ? 'is-danger' : !formMeta.valid && meta.touched ? 'is-success' : ''"
          :message="errorMessage"
        >
          <b-input
            v-model="feedbackEmail"
            v-bind="field"
            name="email"
            :placeholder="$t('feedback-email-label')"
            type="text"
            icon="envelope-open-text"
          />
        </b-field>
      </Field>

      <b-field>
        <b-input
          v-model="feedbackPhone"
          name="contactPhone"
          :placeholder="$t('feedback-phone-label')"
          type="text"
          icon="mobile"
        />
      </b-field>
      <div
        class="buttons mt-2"
        style="float: right"
      >
        <b-button
          class="is-feedback-buttons button"
          :disabled="!formMeta.valid"
          @click.prevent="recordFeedback"
        >
          {{ $t('feedback-submit-button-label') }}
        </b-button>
      </div>
    </div>
    <div class="has-text-centered mt-4">
      <img
        style="max-width: 16em; max-height: 3.5em"
        class="logo-background pt-2 pb-2"
        :src="`img/${omwConfig.display.companyLogo}`"
        alt=""
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, getCurrentInstance, nextTick, inject } from 'vue';
import { defineRule, Field, useForm } from 'vee-validate';
import { useScreenOrientation } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import type { Feedback, FeedbackScreen } from '@/types/FeedbackTypes';
import { configKey } from '@/symbols';
import { useOmwStore } from '@/store/omw';
import useFeedback from '@/services/feedback';

const omwStore = useOmwStore();
const { submitFeedback } = useFeedback();
const { meta: formMeta } = useForm();
const self = getCurrentInstance();
const { t } = useI18n();
const omwConfig = inject(configKey)!;

// TODO this form should "remember" the last rating and feedback text if closed and reopened

defineRule('email', (value: string) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  // Check if email using regex from: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  if (
    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
      value,
    )
  ) {
    // TODO - i18n
    return 'Please provide a valid email so we can contact you';
  }
  return true;
});

const emit = defineEmits(['close']);

const { orientation } = useScreenOrientation();

const localScreen = ref<FeedbackScreen>('rating');
const feedbackText = ref('');
const feedbackEmail = ref('');
const feedbackPhone = ref('');
const feedbackName = ref('');
const feedbackRating = ref(0);
const feedbackNps = ref(-1);
const ratingSelected = ref(false);

const sliderClass = computed(() => {
  let returnVal;
  if (feedbackNps.value < 7) {
    returnVal = 'is-feedback-bad';
  } else if (feedbackNps.value >= 7 && feedbackNps.value < 9) {
    returnVal = 'is-feedback-ok';
  } else {
    returnVal = 'is-feedback-good';
  }
  return returnVal;
});

const showNext = computed(() => {
  return ratingSelected.value && feedbackNps.value > -1;
});

const feedbackDivStyle = computed(() => {
  return orientation.value?.includes('landscape') ? { 'overflow-y': 'scroll' } : { 'overflow-y': 'none' };
});

// TODO - i18n
watch(localScreen, (screen) => {
  if (screen === 'finish') {
    self?.proxy?.$buefy.toast.open({
      message: t(`We read all feedback, thank you for taking the time to help us improve our service for you.`),
      type: 'is-primary has-text-weight-semibold',
      duration: omwConfig.display.toastDuration ?? 5000,
      position: 'is-bottom',
    });
    emit('close');
  }
});

const processFeedback = () => {
  // Use nextTick to ensure feedbackRating is updated prior to processing
  nextTick(() => {
    ratingSelected.value = true;
    showFeedbackTextScreen(feedbackRating.value);
  });
};

const showFeedbackTextScreen = (rating: number) => {
  feedbackRating.value = rating;
  localScreen.value = 'feedbackText';
};

const showFeedbackContactScreen = () => {
  localScreen.value = 'feedbackContact';
};

const recordFeedback = async () => {
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  const feedbackResponse: Feedback = {
    appointmentNumber: omwStore.activityDetails?.apptNumber!,
    datasetId: omwStore.datasetId!,
    apptDate: omwStore.activityDetails?.date!,
    timezone: omwStore.activityTimezone,
    feedbackDate: DateTime.utc().toISO(),
    token: omwStore.token!,
    url: window.location.href,
    nps: feedbackNps.value,
    message: feedbackText.value,
    name: feedbackName.value,
    email: feedbackEmail.value,
    phone: feedbackPhone.value,
    rating: feedbackRating.value,
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    language: navigator.language,
    connection: connection ? connection.effectiveType : undefined,
    product: navigator.product,
  };
  try {
    await submitFeedback(feedbackResponse);
    localScreen.value = 'finish';
  } catch (err) {
    self?.proxy?.$buefy.toast.open({
      message: t('Something went wrong when submitting your feedback, please try again'), // TODO i18n
      type: 'is-danger',
      duration: omwConfig.display.toastDuration ?? 5000,
    });
  }
};
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';
$footer-background-color: white;

.field {
  margin-bottom: 2em;
}

.feedback-form-style {
  background-color: white;
  opacity: 1;
  padding: 1em;
  margin: 1em;
  height: 550px;
}
.textarea-style {
  resize: none !important;
  outline: none;
  border: none;
  background-color: #f5f5f5;
  font-size: 1rem;
  width: 100%;
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.full-width {
  width: 100%;
}
.pad-top {
  padding-top: 0.7em;
}
.footer-layout {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 800;
  margin-top: 1em;
  margin-bottom: 2em;
  margin-left: -2em;
  text-align: center;
}
.logo-background {
  background-color: $header-background;
}
</style>

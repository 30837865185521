<template>
  <div
    class="modal-card"
    style="width: auto"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">{{ t('notes-modal-title') }}</p>
    </header>
    <section class="modal-card-body">
      <div
        v-for="note in previousNotes"
        :key="note.timestamp"
      >
        <b-message
          :closable="false"
          size="is-small"
          style="margin-bottom: 0.4em"
        >
          <span class="has-text-weight-semibold">{{ convertTimestamp(note.timestamp) }} </span>: {{ note.text }}
        </b-message>
      </div>
      <b-field v-if="!maxNotesReached">
        <b-input
          v-model="newNoteText"
          :maxlength="maxNoteLength"
          type="textarea"
          :placeholder="t('notes-input-placeholder')"
        />
      </b-field>
      <p
        v-else
        class="pt-2 has-text-weight-semibold has-text-centered"
      >
        {{ t('notes-max-reached-label') }}
      </p>
      <p class="is-size-7 has-text-centered">
        {{ t('notes-disclaimer-label') }}
      </p>
    </section>
    <footer class="modal-card-foot">
      <b-button @click="emit('close')">{{ t('notes-close-button-label') }}</b-button>
      <b-button
        type="is-success"
        :disabled="isNoteTextEmpty"
        @click="addNote"
      >
        {{ t('notes-add-button-label') }}
      </b-button>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, computed, onMounted, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import { useOmwStore } from '@/store/omw';
import useNotes from '@/services/notes';
import { configKey } from '@/symbols';
import type { NotesResponse, Note } from '@/types/NoteTypes';

const omwStore = useOmwStore();
const omwConfig = inject(configKey)!;
const { t } = useI18n();
const self = getCurrentInstance();
const { getNotes, postNote } = useNotes();

const previousNotes = ref<Note[]>([]);
const newNoteText = ref<string>();
const maxNotesReached = ref(false);
const maxNoteLength = omwConfig.notes.maxNoteLength || 300;

const isNoteTextEmpty = computed(() => !newNoteText.value || newNoteText.value?.length === 0);

const emit = defineEmits(['close']);

onMounted(async () => {
  try {
    handleNotesResponse(await getNotes(omwStore.token!));
  } catch (err) {
    self?.proxy?.$buefy.toast.open({
      message: t('notes-fetch-failure-message'),
      type: 'is-danger',
    });
  } finally {
    omwStore.isLoading = false;
  }
});

function convertTimestamp(timestamp: string) {
  return DateTime.fromISO(timestamp).toFormat(omwConfig.notes.dateTimeFormat);
}

async function addNote() {
  try {
    omwStore.isLoading = true;
    handleNotesResponse(await postNote(omwStore.token!, newNoteText.value!));
    newNoteText.value = undefined;
    self?.proxy?.$buefy.toast.open({
      message: t('notes-add-success-message'),
      type: 'is-success',
    });
  } catch (err) {
    self?.proxy?.$buefy.toast.open({
      message: t('notes-add-failure-message'),
      type: 'is-danger',
    });
  } finally {
    omwStore.isLoading = false;
  }
}

function handleNotesResponse(notesResponse: NotesResponse) {
  previousNotes.value = notesResponse.notes;
  maxNotesReached.value = !!notesResponse.maxAllowed && notesResponse.notes.length >= notesResponse.maxAllowed;
}
</script>

import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowUp,
  faCalendarDay,
  faCheckCircle,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faEnvelopeOpenText,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileSignature,
  faInfoCircle,
  faLeaf,
  faMobile,
  faPaperclip,
  faQuestionCircle,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faStar,
  faTimesCircle,
  faTrash,
  faTruck,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowUp,
  faCalendarDay,
  faCheckCircle,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faEnvelopeOpenText,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileSignature,
  faInfoCircle,
  faLeaf,
  faMobile,
  faPaperclip,
  faQuestionCircle,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faStar,
  faTimesCircle,
  faTrash,
  faTruck,
  faUpload,
);

<template>
  <div class="content">
    <div class="columns is-mobile is-align-items-center">
      <div class="column has-text-centered">
        <img
          v-if="photoSource"
          id="imgEngineer"
          class="mt-1 circular--landscape"
          :src="photoSource"
          :alt="engineerName()"
        />
        <div
          v-if="engineerMessage"
          id="divEngMsg"
          class="has-text-weight-semibold"
        >
          {{ engineerMessage }}
        </div>
        <licence-plate v-if="omwConfig.display.showLicencePlate" />
      </div>
      <div
        v-if="funFact || (filteredLogos && filteredLogos.length > 0)"
        class="column"
      >
        <p
          v-if="funFact"
          id="pEngFact"
          class="has-text-centered has-text-weight-semibold is-italic pr-4 fun-fact-padding"
        >
          {{ funFact }}
        </p>
        <div
          v-if="filteredLogos && filteredLogos.length > 0"
          id="divEngLogos"
          class="columns is-mobile"
        >
          <div
            v-for="logo in filteredLogos"
            :key="logo.filename"
            class="column"
          >
            <logo-container
              :logo="logo.filename"
              :alt-text="logo.label"
              :title="logo.label"
              :value="logo.value"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, computed } from 'vue';
import { useOmwStore } from '@/store/omw';
import LogoContainer from '@/components/LogoContainer.vue';
import LicencePlate from '@/components/optional/LicencePlate.vue';
import useUtilities from '@/composables/useUtilities';
import { configKey } from '@/symbols';
import { useI18n } from 'vue-i18n';

const omwConfig = inject(configKey)!;
const omwStore = useOmwStore();
const { t } = useI18n();

const { engineerName } = useUtilities();

const filteredLogos = computed(() =>
  !omwStore.engineerDetails?.type
    ? omwConfig.logos
    : omwConfig.logos.filter(
        (logo) => logo.resourceTypes?.includes(omwStore.engineerDetails?.type) || !logo.resourceTypes,
      ),
);

const licencePlate = computed(() =>
  omwConfig.display.showLicencePlate && omwStore.engineerDetails?.licencePlate
    ? omwStore.engineerDetails?.licencePlate
    : false,
);

const funFact = computed(() => {
  if (!omwStore.apptToday) return undefined;
  return omwStore.engineerDetails?.funFact;
});

const isUnassigned = computed(() => omwStore.apptInFuture);

const photoSource = computed(() => {
  if (!omwStore.engineerDetails) return '';
  const engineerKeys = Object.keys(omwStore.engineerDetails);
  const defaultPhoto = `img/${omwConfig.display.defaultEngineerPhoto}`;
  if (omwStore.apptInFuture && omwConfig.display.defaultEngineerPhoto) {
    return defaultPhoto;
  }
  if (omwStore.apptInFuture) {
    return undefined;
  }
  if (!engineerKeys.includes('photo') || omwStore.engineerDetails['photo'].match(/No photo available/gi)) {
    return omwConfig.display.defaultEngineerPhoto ? defaultPhoto : undefined;
  }
  return omwStore.engineerDetails['photo'];
});

const engineerMessage = computed(() => {
  if (isUnassigned.value) return '';
  let msg = '';
  if (omwStore.engineerDetails?.nameOverride === omwConfig.display.defaultEngineerName) {
    return msg;
  }
  if (!engineerName()) {
    return t('eng-panel-unknown-engineer');
  }
  if (omwStore.apptInPast) {
    return '';
  }
  if (!omwStore.apptInFuture) {
    if (['pending', 'enroute'].includes(omwStore.activityDetails?.status!)) {
      msg += t('eng-panel-today-pending', {
        name: engineerName(),
      });
    } else if (omwStore.activityDetails?.status === 'started') {
      msg += t('eng-panel-today-started', {
        name: engineerName(),
      });
    } else {
      msg += t('eng-panel-today-finished', {
        name: engineerName(),
      });
    }
  } else {
    msg = t(`eng-panel-future`);
  }
  return msg;
});
</script>

<style lang="scss" scoped>
@use 'sass:meta';

@import '@/sass/variables.scss';

.content {
  background-color: $engineer-panel;
  color: $engineer-panel-text;
  border-radius: 4px;
  box-shadow: 0 0 0 0 black; // invisible outline to prevent margin collapse
  @if meta.variable-exists('engineer-panel-border') {
    outline-color: $engineer-panel-border;
    outline-style: solid;
  }
}

.aligned {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.fun-fact-padding {
  @media screen and (min-width: 768px) {
    padding-top: 3rem;
  }
}
.circular--landscape {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.circular--landscape img {
  width: auto;
  height: 100%;
  margin-left: -25px;
}
</style>

<template>
  <div id="app">
    <img
      v-if="backgroundImage"
      :src="backgroundImage"
      class="bg"
      alt=""
    />
    <div
      class="header-style"
      :style="headerStyleObject"
    >
      <div :style="logoContainerStyle">
        <a
          :href="websiteUrl"
          target="_new"
        >
          <img
            :src="`img/${omwConfig.display.companyLogo}`"
            :style="headerImageStyleObject"
            alt=""
          />
        </a>
      </div>
    </div>

    <div
      v-if="omwConfig.display.separatorEnabled"
      class="header-separator"
    />
    <div class="app-background pb-2 main-content">
      <div
        v-if="omwConfig.display.separatorEnabled"
        style="height: 2em"
        class="app-background"
      />
      <span v-if="omwStore.error">
        <error-screen />
      </span>
      <span v-else>
        <router-view v-slot="{ Component }">
          <keep-alive :exclude="['Home', 'MainContainer', 'AppointmentRebookContainer', 'PhotoAttach']">
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </span>
    </div>
    <the-footer class="footer" />
  </div>
</template>

<script setup lang="ts">
import { reactive, inject, computed, watch, onMounted, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import ErrorScreen from '@/components/ErrorScreen.vue';
import TheFooter from '@/components/layout/TheFooter.vue';
import { useOmwStore } from '@/store/omw';
import { configKey } from '@/symbols';

const omwConfig = inject(configKey)!;
const omwStore = useOmwStore();
const route = useRoute();

const headerStyleObject = reactive({
  height: omwConfig?.display.headerHeight,
});

const websiteUrl = omwConfig?.display.customerWebsite;

const backgroundImage = computed(() => {
  if (omwConfig?.display.backgroundImage) {
    return `img/${omwConfig.display.backgroundImage}`;
  }
  return undefined;
});

const logoContainerStyle = computed(() => {
  const style: Record<string, string> = {};
  if (omwConfig?.display.companyLogoPosition) {
    style.display = 'flex';
    style['justify-content'] = omwConfig.display.companyLogoPosition?.position || 'left';
    style.padding = omwConfig.display.companyLogoPosition.padding || '0 0 0 0.25em';
  }
  return style;
});

const headerImageStyleObject = computed(() => {
  const style: Record<string, string> = {
    height: omwConfig?.display.imageHeight || '4em',
    width: omwConfig?.display.imageWidth || 'auto',
  };
  if (omwConfig?.display.companyLogoPosition?.margin) {
    style.margin = omwConfig.display.companyLogoPosition.margin;
  }
  if (omwConfig?.display.companyLogoPosition?.padding) {
    style.padding = omwConfig.display.companyLogoPosition.padding;
  }
  return style;
});

const routeQuery = computed(() => {
  return route.query;
});

watch(
  routeQuery,
  () => {
    const token = routeQuery.value.token as string;
    omwStore.token = token ?? omwStore.landingToken;
    omwStore.clickOrigin = routeQuery.value.clickOrigin as string;
  },
  { once: true },
);

onBeforeMount(() => {
  if (omwConfig.trustpilot.enabled) {
    const trustpilotScript = document.createElement('script');
    trustpilotScript.setAttribute('src', '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
    trustpilotScript.setAttribute('async', 'async');
    document.head.appendChild(trustpilotScript);
  }
});

onMounted(async () => {
  omwStore.error = undefined;
});
</script>

<style lang="scss">
@import '@/sass/buefy-init.scss';
@import '@/sass/variables.scss';

#app {
  font-family: Custom, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@if variable-exists(custom-font-family) {
  @font-face {
    font-family: 'Custom';
    src: url($custom-font-family);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease-in;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@if variable-exists(button-border-radius) {
  button {
    border-radius: $button-border-radius !important;
  }
}

.header-style {
  background-color: $header-background;
}
.app-background {
  background-color: $main-background;
  color: $font-color;
}

img.bg {
  min-height: 100vh;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.header-separator {
  background-color: $separator-color;
  width: 100vw;
  height: 30px;
}
.main-content {
  min-height: calc(100vh - 128px);
}
</style>

import { inject } from 'vue';
import { DateTime } from 'luxon';
import { useOmwStore } from '@/store/omw';
import { configKey } from '@/symbols';
import type { OmwResponseActivity } from '@/types/AppointmentTypes';

export default function useFormattedTimeSlot() {
  const omwStore = useOmwStore();
  const omwConfig = inject(configKey)!;
  const TIME_FORMAT = omwConfig.display.timeFormat || 'hh:mm';

  function formatTimeSlotValue(
    fieldName: 'trimServiceWindowStart' | 'trimServiceWindowEnd' | 'trimDeliveryWindowStart' | 'trimDeliveryWindowEnd',
    activityDetails: OmwResponseActivity,
  ) {
    if (!activityDetails[fieldName]) return;
    let parsed = DateTime.fromISO(activityDetails.date);

    const time = activityDetails[fieldName].split(':');
    parsed = parsed.set({
      hour: parseInt(time[0]),
      minute: parseInt(time[1]),
    });

    return parsed.toFormat(TIME_FORMAT);
  }

  const formattedServiceWindowStart = (() => {
    if (!omwStore.activityDetails?.date) return undefined;
    return formatTimeSlotValue('trimServiceWindowStart', omwStore.activityDetails);
  })();

  const formattedServiceWindowEnd = (() => {
    if (!omwStore.activityDetails?.date) return undefined;
    return formatTimeSlotValue('trimServiceWindowEnd', omwStore.activityDetails);
  })();

  const formattedDeliveryWindowStart = (() => {
    if (!omwStore.activityDetails?.date) return undefined;
    return formatTimeSlotValue('trimDeliveryWindowStart', omwStore.activityDetails);
  })();

  const formattedDeliveryWindowEnd = (() => {
    if (!omwStore.activityDetails?.date) return undefined;
    return formatTimeSlotValue('trimDeliveryWindowEnd', omwStore.activityDetails);
  })();

  const calculatedEta = (() => {
    const etaDateTime = omwStore.arrivalTime || omwStore.activityDetails?.startTime;
    if (!etaDateTime) return undefined;
    const bufferDateBase = DateTime.fromISO(etaDateTime);

    const bufferedStartDate = bufferDateBase
      .minus({
        minutes: omwConfig.display.eta.bufferBeforeMins,
      })
      .toFormat(TIME_FORMAT);

    const bufferedEndDate = bufferDateBase
      .plus({
        minutes: omwConfig.display.eta.bufferAfterMins,
      })
      .toFormat(TIME_FORMAT);

    return {
      bufferedStartDate,
      bufferedEndDate,
    };
  })();

  return {
    formattedServiceWindowStart,
    formattedServiceWindowEnd,
    formattedDeliveryWindowStart,
    formattedDeliveryWindowEnd,
    calculatedEta,
  };
}

<template>
  <div class="container is-fluid">
    <p
      v-if="typeof omwStore.error === 'string'"
      class="is-size-3 has-text-centered pt-4"
      v-html="omwStore.error"
    />
    <p
      v-else-if="omwStore.error && 'error' in omwStore.error"
      class="is-size-3 has-text-centered pt-4"
      v-html="$t(omwStore.error.error)"
    />

    <div class="has-text-centered columns is-centered">
      <marketing-campaign
        v-if="omwConfig.marketing.enabled"
        class="column is-half pt-6"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import { useOmwStore } from '@/store/omw';
import { configKey } from '@/symbols';
import MarketingCampaign from '@/components/optional/MarketingCampaign.vue';

const omwStore = useOmwStore();
const omwConfig = inject(configKey)!;
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';
h1 {
  font-size: 2rem;
  text-align: center;
  display: block;
  padding: 3em;
  font-weight: 500;
}
img {
  display: block;
  margin: auto auto;
  max-width: 50vw;
}
.logo-background {
  background-color: $header-background;
}
</style>

<template>
  <div>
    <b-loading
      v-model="omwStore.isLoading"
      :is-full-page="true"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useOmwStore } from '@/store/omw';
import useUtilities from '@/composables/useUtilities';
import { useRoute } from 'vue-router';

/**
 * This is a special component to support a route into the app where the token is provided as a path param. Such URLs only
 * to be used when distributing URLs via SMS. It redirects to the loading root, placing the token in the query params.
 */

const omwStore = useOmwStore();
const { routeToPage } = useUtilities();
const route = useRoute();

onMounted(() => {
  // This is in place as a back-up, as for an as yet unknown reason we get previous visitors returning to the site without a token in the URL
  omwStore.landingToken = route.query.token as string;
  routeToPage('Loading');
});
</script>

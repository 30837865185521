<template>
  <div>
    <span
      v-show="!isFeedbackComponentActive"
      class="feedback-tag-position feedback-style rotated"
      style="cursor: pointer"
      @click="startFeedback"
    >
      <p class="has-text-weight-semibold">
        {{ $t('customer-feedback-handle-label') }}
      </p>
    </span>
    <div>
      <b-modal
        v-model="isFeedbackComponentActive"
        trap-focus
        custom-class="form-height"
      >
        <feedback-form
          v-if="isFeedbackComponentActive"
          style="justify-content: center"
          @close="isFeedbackComponentActive = !isFeedbackComponentActive"
        />
      </b-modal>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import FeedbackForm from '@/components/optional/feedback/FeedbackForm.vue';

const emit = defineEmits(['feedback-active']);

const isFeedbackComponentActive = ref(false);

watch(isFeedbackComponentActive, (newVal) => {
  emit('feedback-active', newVal);
});

function startFeedback() {
  isFeedbackComponentActive.value = true;
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.feedback-tag-position {
  z-index: 39;
  position: fixed;
  right: 0;
  top: 57%;
  margin-top: -3em;
  padding-top: 1em;
  padding-bottom: 1em;
  border-right: none;
}
.feedback-style {
  border-radius: 0px 5px 5px 0px;
  background-color: $feedback-handle;
  color: $feedback-handle-text;
  width: 2em;
  font-weight: 500;
}
.rotated {
  -webkit-writing-mode: vertical-rl;
  -moz-writing-mode: vertical-rl;
  -ms-writing-mode: vertical-rl;
  -o-writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
}
.form-height {
  height: 100vh;
  z-index: 999;
}
</style>

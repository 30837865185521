import { inject } from 'vue';
import { configKey } from '@/symbols';
import type { AcceptSlotParams, CancelRequestParams, RequestSlotsParams } from '@/types/RebookTypes';

export default function useReschedule() {
  const omwConfig = inject(configKey)!;
  const baseUrl = `${omwConfig?.reschedule?.url}`;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': omwConfig.omwServer.apiKey,
  };

  const getAppointmentData = async function ({ token, dateFrom, numberOfDays, timezone }: RequestSlotsParams) {
    const params = new URLSearchParams({
      numberOfDays: numberOfDays.toString(),
      dateFrom,
      timezone,
    });
    const response = await fetch(`${baseUrl}/rebook/${token}/slots?${params}`, {
      method: 'GET',
      headers,
    });
    return response.json();
  };

  const cancelAppointment = async function ({ token }: CancelRequestParams) {
    const response = await fetch(`${baseUrl}/cancel/${token}`, {
      method: 'DELETE',
      headers,
    });
    if (!response.ok) {
      throw new Error('API Error');
    }
  };

  const acceptSlot = async function ({ token, slotId, apptRequestId, startTime, endTime }: AcceptSlotParams) {
    const data = {
      slotId,
      startTime,
      endTime,
    };
    const response = await fetch(`${baseUrl}/rebook/${token}/accept/${apptRequestId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return true;
    }
    if (response.status === 410) {
      // Slot has been taken by another customer
      return false;
    }
    throw new Error('API Error');
  };

  return { getAppointmentData, cancelAppointment, acceptSlot };
}

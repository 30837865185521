<template>
  <em class="is-size-5"> {{ $t('app-last-updated-text') }} {{ msg }} </em>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, inject } from 'vue';
import { DateTime } from 'luxon';
import formatTime from '@/utils/formatTime';
import { configKey } from '@/symbols';
const omwConfig = inject(configKey)!;

const msg = ref<string | null>('');
const intervalHandle = ref<number>();

const autoUpdate = omwConfig.display.autoUpdateInterval || 10 * 1000;
const startDateTime = DateTime.now();

onMounted(() => {
  msg.value = formatTime(startDateTime, DateTime.now());
  intervalHandle.value = window.setInterval(() => {
    msg.value = formatTime(startDateTime, DateTime.now());
  }, autoUpdate);
});

onBeforeUnmount(() => {
  if (intervalHandle.value) {
    clearInterval(intervalHandle.value);
  }
});
</script>

<template>
  <span>
    <b-button
      class="is-home-button has-text-weight-semibold"
      @click="goHome()"
    >
      {{ $t('rebook-home-button-label') }}
    </b-button>
  </span>
</template>

<script setup lang="ts">
import { useOmwStore } from '@/store/omw';
import useUtilities from '@/composables/useUtilities';

const omwStore = useOmwStore();
const { routeToHomePage } = useUtilities();

const goHome = () => {
  omwStore.rescheduleDetails = undefined;
  routeToHomePage('replace');
};
</script>

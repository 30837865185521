<template>
  <div>
    <b-loading
      v-model="omwStore.isLoading"
      :is-full-page="true"
    />

    <div
      v-if="omwConfig?.omwServer.passcodeEnabled && !omwStore.previouslyLoggedIn"
      class="centered"
    >
      <form
        @submit.prevent="submitPasscode"
        @keyup.enter="submitPasscode()"
      >
        <div class="columns">
          <div class="column">
            <b-field
              :label="t('passcode-input-label')"
              style="padding-top: 1em; text-align: center"
            >
              <b-input
                v-model="passcode"
                style="width: 250px"
                :placeholder="t('passcode-input-placeholder')"
                type="password"
                password-reveal
                @keyup.enter="submitPasscode"
              />

              <b-button
                class="button is-primary"
                :disabled="!passcode"
                @click="submitPasscode"
              >
                {{ t('passcode-button-label') }}
              </b-button>
            </b-field>
            <p
              v-if="passcodeFailed"
              class="is-size-6"
              style="text-align: center; color: red"
            >
              {{ t('passcode-incorrect-message') }}
            </p>
          </div>
        </div>
      </form>
    </div>

    <p
      v-else
      class="has-text-weight-semibold is-size-4 has-text-grey has-text-centered is-italic"
      style="padding-top: 5em"
    >
      {{ t('app-finding-appointment-text') }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref, onActivated, inject } from 'vue';
import { useOmwStore } from '@/store/omw';
import useUtilities from '@/composables/useUtilities';
import { configKey } from '@/symbols';
import { useI18n } from 'vue-i18n';

const omwConfig = inject(configKey);
const omwStore = useOmwStore();
const { routeToPage, getData, accessGranted } = useUtilities();

const passcode = ref<string>();
const passcodeFailed = ref(false);
const { t } = useI18n();

onActivated(async () => {
  passcode.value = omwStore.passcode;
  if (!passcode.value) omwStore.previouslyLoggedIn = false;
  if (accessGranted) {
    // If passcode not required, or we've already successfully entered with one, then load home page immediately,
    // else this will be called when passcode is submitted
    await loadHomePage();
  }
});

async function submitPasscode() {
  omwStore.passcode = passcode.value;
  await loadHomePage();
}

async function loadHomePage() {
  if (await getData()) await routeToPage('Home');
  else passcodeFailed.value = true;
}
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-background {
  background-color: $header-background;
}
</style>

import { inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useOmwStore } from '@/store/omw';
import { configKey } from '@/symbols';
import useOmwService from '@/services/omwData';

type RouteQuery = { token: string | null; clickOrigin?: string; clickUrl?: string; marketingPreviewUrl?: string };

export default function useUtilities() {
  const route = useRoute();
  const router = useRouter();
  const omwStore = useOmwStore();
  const { t } = useI18n();
  const omwConfig = inject(configKey)!;
  const { getOmwData } = useOmwService();

  const routeToHomePage = async function (method: 'push' | 'replace' = 'push', reloadData = true) {
    if (reloadData) await getData();
    await routeToPage('Home', method);
  };

  const routeToPage = async function (name: string, method: 'push' | 'replace' = 'push', params = undefined) {
    const query: RouteQuery = {
      token: omwStore.token!,
    };
    if (omwStore.clickOrigin) query.clickOrigin = omwStore.clickOrigin;

    // If this being called as result of a preview from the portal, propagate the preview and click url
    if (route?.query?.marketingPreviewUrl) query.marketingPreviewUrl = `${route.query.marketingPreviewUrl}`;
    if (route?.query?.clickUrl) query.clickUrl = `${route.query.clickUrl}`;

    await router[method]({ name, query, params });
  };

  const getData = async function () {
    if (!omwStore.token || omwStore.token === '') {
      omwStore.error = t('error-invalid-url');
      return false;
    }
    if (omwConfig.omwServer.passcodeEnabled && !omwStore.passcode) {
      return false;
    }
    try {
      omwStore.isLoading = true;
      const data = await getOmwData(omwStore.token, omwStore.clickOrigin, omwStore.passcode);
      omwStore.activityDetails = data.activity;
      omwStore.engineerDetails = data.resource;
      omwStore.apptWindowEnd = data.apptWindowEnd;
      omwStore.datasetId = data.datasetId || data.ofscInstance;
      omwStore.previouslyLoggedIn = true;
      return omwStore.isDataLoaded;
    } catch (err: any) {
      if (err.message === '401') {
        omwStore.previouslyLoggedIn = false;
      } else {
        omwStore.error = t('error-appointment-not-viewable');
      }
      return false;
    } finally {
      omwStore.isLoading = false;
    }
  };

  const accessGranted = (() => !omwConfig.omwServer.passcodeEnabled || omwStore.previouslyLoggedIn)();

  const engineerName = function () {
    return omwStore.apptInFuture
      ? t('future-engineer-name')
      : omwStore.engineerDetails?.nameOverride || t('unknown-engineer-name');
  };

  return { routeToHomePage, routeToPage, getData, accessGranted, engineerName };
}

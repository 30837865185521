import { createI18n } from 'vue-i18n';

import en from './en.json';
import enUS from './enUS.json';
import de from './de.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import dateFormats from './dateFormat';
const datetimeFormats = dateFormats;

const locale = navigator.language;

const messages = {
  en,
  de,
  es,
  fr,
  it,
  'en-US': enUS,
};

type MessageSchema = typeof en;

const i18n = createI18n<[MessageSchema], 'en'>({
  legacy: false,
  locale,
  datetimeFormats,
  messages,
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  missingWarn: false,
  globalInjection: true,
});

export default i18n;

<template>
  <div>
    <b-loading
      v-model="omwStore.isLoading"
      :is-full-page="true"
    />
    <div v-if="omwStore.activityDetails">
      <transition-group name="fade">
        <div :key="'mainGroup'">
          <main-container class="mx-2 main-container" />
          <feedback-container
            v-if="omwConfig.feedback.enabled"
            feedbackstyle="cursor: pointer;"
            @feedback-active="setHideIntercom"
          />
        </div>
      </transition-group>
    </div>
    <div
      v-once
      id="notificationContainer"
      ref="notificationContainer"
      class="notification-container"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, inject, onMounted, nextTick, onActivated, getCurrentInstance } from 'vue';
import { useIntercom } from '@homebaseai/vue3-intercom';
import { useI18n } from 'vue-i18n';

import { configKey } from '@/symbols';
import { useOmwStore } from '@/store/omw';
import MainContainer from '@/components/MainContainer.vue';
import FeedbackContainer from '@/components/optional/feedback/FeedbackContainer.vue';
import useUtilities from '@/composables/useUtilities';
import useFeedback from '@/services/feedback';

const omwConfig = inject(configKey)!;
const omwStore = useOmwStore();
const { routeToPage } = useUtilities();
const { submitFeedback } = useFeedback();
const intercom = useIntercom();
const self = getCurrentInstance();

const hideIntercomLauncher = ref(false);
const { t } = useI18n();

onMounted(() => {
  // If data is not loaded, we have come to the home page directly (bypassing the loading page), e.g. via a page
  // refresh. Redirect to the loading page to check access and load data.
  if (!omwStore.isDataLoaded) {
    routeToPage('Loading');
    return;
  }
  handleShowNotification();
});

const handleShowNotification = () => {
  if (omwConfig.display.notification.show && !omwStore.dismissNotification) {
    self?.proxy?.$buefy.dialog.alert({
      title: t('notification-title'),
      message: t('notification-message'),
      type: 'is-home-button',
      ariaRole: 'alertdialog',
      ariaModal: true,
      onConfirm: () => {
        omwStore.dismissNotification = true;
      },
      size: 'is-medium',
      container: 'notificationContainer',
    });
  }
};

onActivated(() => {
  if (omwConfig.intercom?.enabled) {
    nextTick(() => {
      intercom.boot({
        app_id: omwConfig.intercom?.appId!,
        created_at: 0,
        custom_launcher_selector: '',
        email: '',
        hide_default_launcher: false,
        name: '',
        user_id: `${omwStore.activityDetails?.apptNumber}`,
      });
    });
  }
});

const setHideIntercom = (evt: boolean) => {
  hideIntercomLauncher.value = evt;
};

watch(hideIntercomLauncher, () => {
  if (omwConfig.intercom?.enabled) showHideIntercom();
});

const showHideIntercom = () => {
  intercom.update({
    hide_default_launcher: hideIntercomLauncher.value,
    name: '',
  });
};
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.main-container {
  margin-top: -1rem;
}

@media (min-width: 769px) {
  .main-container {
    margin-top: 0;
  }
}

.notification-container {
  position: fixed;
  width: 80vw;
  z-index: 999;
}
</style>

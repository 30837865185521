<template>
  <div>
    <b-loading
      v-model="omwStore.isLoading"
      :is-full-page="true"
    />

    <div
      class="confirmation"
      style="margin-left: auto; margin-right: auto"
    >
      <p
        v-html="
          $t('rebook-confirmation', {
            apptNumber: omwStore.activityDetails?.apptNumber,
            rescheduleDate: appointment,
          })
        "
      ></p>
      <br />
      <p
        v-html="
          $t('rebook-confirm-cancel-contact-us', {
            contactNumber: omwConfig.display.contactCentreNo,
          })
        "
      />
      <p class="mt-2">{{ $t('rebook-update-delay-guidance') }}</p>
    </div>
    <div class="has-text-centered">
      <post-process-home-button />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, computed } from 'vue';
import { useOmwStore } from '@/store/omw';
import { DateTime } from 'luxon';
import PostProcessHomeButton from './PostProcessReturnButton.vue';
import { configKey } from '@/symbols';

const omwConfig = inject(configKey)!;
const omwStore = useOmwStore();

const dateFormat = omwConfig.reschedule.dateFormat!;
const timeFormat = omwConfig.reschedule.timeFormat!;

const appointment = computed(() => {
  if (!omwStore.rescheduleDetails) return '';
  const apptStartTime = DateTime.fromISO(omwStore.rescheduleDetails.startTime, {
    zone: omwStore.activityTimezone,
  });
  const apptEndTime = DateTime.fromISO(omwStore.rescheduleDetails.endTime, {
    zone: omwStore.activityTimezone,
  });
  const dateFrom = apptStartTime.toFormat(dateFormat);
  const timeFrom = apptStartTime.toFormat(timeFormat).toLowerCase();
  const timeTo = apptEndTime.toFormat(timeFormat).toLowerCase();
  return `<b>${dateFrom} ${timeFrom} - ${timeTo}</b>`;
});
</script>

<style scoped>
.confirmation {
  border: 1px solid lightgrey;
  border-radius: 10px;
  margin: 1em;
  padding: 1em;
  width: 28rem;
}
</style>

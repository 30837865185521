<template>
  <footer class="footer">
    <p
      class="footer-text"
      v-html="footerText"
    />
  </footer>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { configKey } from '@/symbols';

const omwConfig = inject(configKey)!;

const footerText = computed(() => {
  return omwConfig.display.footerText.replace('{year}', new Date().getFullYear().toString());
});
</script>

<style scoped lang="scss">
.footer-text {
  font-weight: 500;
}
</style>

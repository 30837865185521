<template>
  <div class="image-wrapper logo-container has-text-centered">
    <div>
      <img
        v-if="props.logo"
        :src="`img/${props.logo}`"
        :alt="props.altText"
        :title="props.title"
        class="image-logo"
      />
      <p
        v-else
        class="has-text-weight-semibold"
      >
        {{ props.title }}
      </p>
    </div>
    <p class="has-text-weight-semibold is-size-7">
      {{ props.value }}
    </p>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  logo: string;
  value?: string;
  title?: string;
  altText: string;
}>();
</script>

<style scoped>
.image-logo {
  max-height: 3em;
  width: auto;
}

.logo-container {
  padding-bottom: 1em;
}
</style>

import { defineStore } from 'pinia';
import { DateTime } from 'luxon';
import { useStorage } from '@vueuse/core';
import useOmwService from '@/services/omwData';
import type { OmwResponseActivity, OmwResponseResource } from '@/types/AppointmentTypes';
import type { ErrorMessage } from '@/types/UtilityTypes';
import type { AcceptSlotParams } from '@/types/RebookTypes';
import type { DtgIsoFull } from '@/types/TimeTypes';

const { getOmwData } = useOmwService();

export const useOmwStore = defineStore('OmwStore', {
  state: () => {
    return {
      token: undefined as string | undefined,
      landingToken: useStorage<string | undefined>('omw/landingToken', undefined),
      lang: 'en',
      isLoading: false,
      activityDetails: undefined as OmwResponseActivity | undefined,
      engineerDetails: undefined as OmwResponseResource | undefined,
      dismissNotification: useStorage<boolean>('omw/dismissNotification', false),
      mapInitComplete: false,
      arrivalTime: undefined as DtgIsoFull | undefined,
      rescheduleDetails: undefined as AcceptSlotParams | undefined,
      apptWindowEnd: undefined as string | undefined,
      datasetId: null as string | null,
      clickOrigin: undefined as string | undefined,
      error: undefined as string | ErrorMessage | undefined,
      marketingCampaign: undefined,
      previouslyLoggedIn: useStorage<boolean>('omw/previouslyLoggedIn', false),
      passcode: useStorage<string | undefined>('omw/passcode', undefined),
    };
  },
  getters: {
    isDataLoaded(state) {
      // Use date field as a proxy for know if data is loaded as activityDetails default to {} for Vue rendering reasons
      // TODO: is the above still true?
      return !!state.activityDetails?.date;
    },
    apptToday() {
      return !this.apptInFuture && !this.apptInPast;
    },
    apptInFuture: (state) => {
      if (state.activityDetails?.date) {
        const comparisonDate = DateTime.fromISO(state.activityDetails.date).startOf('day');
        const now = DateTime.now().startOf('day');
        const diff = comparisonDate.diff(now, 'days');
        return diff.days >= 1;
      }
      return false;
    },
    apptInPast: (state) => {
      if (state.activityDetails?.date) {
        const comparisonDate = DateTime.fromISO(state.activityDetails?.date).startOf('day');
        const now = DateTime.now().startOf('day');
        const diff = comparisonDate.diff(now, 'day');
        return diff.days <= -1;
      }
      return false;
    },
    activityTimezone: (state) => {
      if (!state.activityDetails?.timezone) return DateTime.now().zoneName;
      return state.activityDetails?.timezone;
    },
  },
});

import { inject } from 'vue';
import type { Feedback } from '@/types/FeedbackTypes';
import { configKey } from '@/symbols';

export default function useFeedback() {
  const omwConfig = inject(configKey)!;

  const submitFeedback = async function (feedback: Feedback) {
    const response = await fetch(omwConfig.feedback.url!, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': omwConfig.omwServer.apiKey,
      },
      body: JSON.stringify(feedback),
      mode: 'cors',
    });
    if (!response.ok) {
      throw new Error('Failed to post feedback');
    }
  };

  return { submitFeedback };
}

<template>
  <div>
    <div v-if="!omwStore.rescheduleDetails">
      <span class="columns">
        <span
          v-show="omwStore.activityDetails"
          class="column is-half span-block"
        >
          <appointment-details />
          <here-map v-if="showMap" />
        </span>
        <span class="column is-half">
          <engineer-panel />
          <span
            v-if="omwConfig.marketing.enabled && !marketingCampaignFailed"
            class="pt-4 span-block"
          >
            <marketing-campaign
              v-show="marketingReady"
              ref="marketing"
              @campaign-failed="marketingFailed"
              @campaign-loaded="nowShowMarketing"
            />
            <b-skeleton
              :height="engineerPanelHeight"
              size="is-large"
              :active="!marketingReady"
            />
          </span>
          <additional-info v-if="omwConfig.display.additionalInfo?.enabled" />
          <span
            style="text-align: center"
            class="pt-5 faint-box span-block"
          >
            <!-- eslint-disable vue/no-v-html -->
            <p
              v-if="!omwConfig.display.disableContactUs"
              class="is-size-5 has-text-weight-semibold"
              v-html="
                $t('main-rearrange-appointment-text', {
                  contactCentreNumber: omwConfig.display.contactCentreNo,
                })
              "
            ></p>
            <!-- eslint-enable vue/no-v-html -->
            <span class="column has-text-centered mb-2 span-block">
              <span
                v-if="omwConfig.trustpilot.enabled"
                style="display: block; max-height: 40px; padding: 2em 0 5em 0"
              >
                <trust-pilot />
              </span>
              <time-ago />
            </span>
          </span>
        </span>
      </span>
    </div>
    <div v-else>
      <appointment-rebook-confirmation style="height: 80vh" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onUpdated, inject } from 'vue';
import EngineerPanel from '@/components/EngineerPanel.vue';
import HereMap from '@/components/HereMap.vue';
import TimeAgo from '@/components/TimeAgo.vue';
import AppointmentDetails from '@/components/AppointmentDetails.vue';
import AppointmentRebookConfirmation from '@/components/appointmentBooking/AppointmentRebookConfirmation.vue';
import TrustPilot from '@/components/optional/TrustPilot.vue';
import MarketingCampaign from '@/components/optional/MarketingCampaign.vue';

import AdditionalInfo from '@/components/optional/AdditionalInfo.vue';
import { useOmwStore } from '@/store/omw';
import { configKey } from '@/symbols';

const omwStore = useOmwStore();
const omwConfig = inject(configKey)!;

const marketingCampaignFailed = ref(false);
const engineerPanelHeight = ref<number>();
const marketingReady = ref(false);

const showMap = computed(() => omwStore.activityDetails!.latitude || omwStore.engineerDetails!.latitude);

onUpdated(() => {
  // FIXME don't think this ever worked!
  // engineerPanelHeight.value = $refs?.engineerPanel?.$el?.offsetHeight;
});

function nowShowMarketing() {
  marketingReady.value = true;
}

function marketingFailed() {
  marketingCampaignFailed.value = true;
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
$margin: 0.25rem;

.content-desktop {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.content-mobile {
  display: flex;
}
@media screen and (max-width: 768px) {
  .content-desktop {
    display: none;
  }
  .content-mobile {
    display: block;
  }
}

.engineer-marketing-flex-container {
  display: flex;
  flex-flow: wrap row-reverse;
  margin: 0 (-$margin);
  text-align: center;
}

.margin-wrap {
  flex: 1 1 auto;
  padding: 1rem;
  border: $margin;
}

.faint-box {
  @if variable-exists(additional-info-panel-border) {
    border-style: solid;
    border-width: 1px;
    border-color: $additional-info-panel-border;
    border-radius: 4px;
  }
}

.span-block {
  display: block;
}
</style>

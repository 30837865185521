import { inject } from 'vue';
import type { OmwResponseActivity } from '@/types/AppointmentTypes';
import type { MarketingCampaignPreview, MarketingCampaignResponse } from '@/types/MarketingTypes';
import { configKey } from '@/symbols';

export default function useMarketing() {
  const omwConfig = inject(configKey)!;

  const submitClick = async function (
    activityDetails: OmwResponseActivity,
    engineerName: string,
    campaign: MarketingCampaignResponse,
    datasetId: string,
    clickOrigin: string,
  ) {
    const clickSubmissionData = {
      apptNumber: activityDetails.apptNumber,
      apptStatus: activityDetails.status,
      apptDate: activityDetails.date,
      campaignId: campaign.campaignId,
      engineerName,
      datasetId,
      clickDate: new Date(),
      clickOrigin,
    };
    await fetch(omwConfig.marketing.recordClickUrl!, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': omwConfig.omwServer.apiKey,
      },
      body: JSON.stringify(clickSubmissionData),
      mode: 'cors',
    });
  };

  const getCampaign = async function (
    datasetId: string,
    activityDetails: OmwResponseActivity,
    clickOrigin: string,
  ): Promise<MarketingCampaignResponse | MarketingCampaignPreview> {
    // Allow forcing of marketing campaign for preview in portal
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    const marketingPreviewUrl = params.get('marketingPreviewUrl');
    const clickUrl = params.get('clickUrl') || '';
    const submissionParams = new URLSearchParams();

    if (marketingPreviewUrl) {
      // TODO this needs testing
      return {
        url: marketingPreviewUrl,
        clickUrl,
      };
    }
    // Deal with retrieving a marketing campaign
    let url = omwConfig.marketing.retrievalUrl;
    if (omwConfig.marketing.oldStyle) {
      // Caters for the old URL path in use with OFSC customers
      url += `/${omwConfig.marketing.customer}`;
    }
    if (omwConfig.marketing.demo) {
      submissionParams.append('categories', encodeURIComponent(omwConfig.marketing.demoCustomer));
    } else if ((activityDetails as any)[omwConfig.marketing.fieldName]) {
      // FIXME "any" type cast
      submissionParams.append(
        'categories',
        encodeURIComponent((activityDetails as any)[omwConfig.marketing.fieldName]),
      );
    }
    if (activityDetails.apptNumber) {
      submissionParams.append('apptNumber', activityDetails.apptNumber);
    }
    if (activityDetails.date) {
      submissionParams.append('apptDate', activityDetails.date);
    }
    if (activityDetails.status) {
      submissionParams.append('apptStatus', activityDetails.status);
    }
    if (datasetId) {
      submissionParams.append('datasetId', datasetId);
    }
    if (clickOrigin) {
      submissionParams.append('clickOrigin', clickOrigin);
    }

    const response = await fetch(`${url}?${submissionParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': omwConfig.omwServer.apiKey,
      },
      mode: 'cors',
    });

    const data = await response.json();

    if (!data.url) {
      // TODO i18n
      throw new Error('No campaign found');
    }

    if (!data.clickUrl) data.clickUrl = omwConfig.marketing.defaultClickUrl;

    return data;
  };

  return { submitClick, getCampaign };
}

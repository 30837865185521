import { inject } from 'vue';
import type { NotesResponse } from '@/types/NoteTypes';
import { configKey } from '@/symbols';

export default function useNotes() {
  const omwConfig = inject(configKey)!;

  const getNotes = async function getNotes(token: string): Promise<NotesResponse> {
    const params = new URLSearchParams({ token });
    const response = await fetch(`${omwConfig.notes.url}?${params}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': omwConfig.omwServer.apiKey,
      },
      mode: 'cors',
    });
    return await response.json();
  };

  const postNote = async function postNote(token: string, note: string): Promise<NotesResponse> {
    const params = new URLSearchParams({ token });
    const response = await fetch(`${omwConfig.notes.url}?${params}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'x-api-key': omwConfig.omwServer.apiKey,
      },
      mode: 'cors',
      body: JSON.stringify({ text: note }),
    });
    return await response.json();
  };

  return { getNotes, postNote };
}
